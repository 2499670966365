<template>
  <div :class="$style.page">
    <adw-button :class="$style.button" color="primary" @click="submit">
      Cохранить
    </adw-button>
    <el-input
      v-model="robotsTxtContent"
      :class="$style.textarea"
      placeholder="Robots txt"
      type="textarea"
      resize="none"
    />
  </div>
</template>
<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'

export default {
  mixins: [notifications],
  data() {
    return {
      robotsTxtContent: ''
    }
  },
  async mounted() {
    await this.getRobotsTxtContent()
  },
  methods: {
    async getRobotsTxtContent() {
      const { value, error } =
        await delivery.ContentServiceCore.SeosActions.getRobotsTxtContent()

      if (error) return

      this.robotsTxtContent = value
    },
    async createFileLink() {
      const file = new File([this.robotsTxtContent], 'robots.txt', {
        type: 'text/plain'
      })

      const response = await delivery.StorageServiceCore.FilesActions.upload(
        file
      )

      return response
    },
    async submit() {
      const { value: link, error: linkError } = await this.createFileLink()

      if (linkError) {
        this.showNotification('Ошибка сохранения', 'error')
        return
      }

      const data = {
        link
      }

      const { error } =
        await delivery.ContentServiceCore.SeosActions.updateRobotsTxt(data)

      if (error) {
        this.showNotification('Ошибка сохранения', 'error')
        return
      }

      this.showNotification('Успешно сохранено', 'success')

      await this.getRobotsTxtContent()
    }
  }
}
</script>
<style lang="scss" module>
.page {
  padding: 1rem;

  .button {
    @include stickyWrapper;
    margin-bottom: 1rem;
  }

  .textarea {
    height: 35rem;

    & > textarea {
      height: 100%;
    }
  }
}
</style>
